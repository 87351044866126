import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const FeatureGrid = ({ gridItems }) => (
  <React.Fragment>
    {gridItems.map(item => (
      <div key={item.text} className="column col">
        <section>
          <div className="has-text-centered">
            <PreviewCompatibleImage imageInfo={item} />
          </div>
          <a href={item.heading.toLowerCase()}>
            <h5
              style={{
                marginTop: "20px"
              }}
            >
              {item.heading}
            </h5>
          </a>
          <p className="smaller-mobile-p">{item.text}</p>
        </section>
      </div>
    ))}
  </React.Fragment>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      heading: PropTypes.string,
      text: PropTypes.string
    })
  )
};

export default FeatureGrid;
